export function useIsOnMounted() {
  const isMounted = ref(false)

  onMounted(() => {
    isMounted.value = true
  })

  return {
    isMounted,
  }
}
